export const colors = {
  black: "#000000",
  white: "#ffffff",
  blueLight: "#f0f4fe",
  blueDark: "#000e7a",
  grayDark: "#2f2f2f",
  gray: "#5f5f5f",
  grayLight: "#f0f4fe",
  red: "#161E2C",
  redHover: "#293853",
  blue: "#000952",
  secondary: "#02AFDD",
  grayBlue: "#757f99",
  blueGradient:
    "linear-gradient(to bottom, #f0f4fe 0%, rgba(240, 244, 254, 0) 100%);"
};

export const breakpoints = {
  zero: 0,
  xs: 480,
  s: 520,
  m: 720,
  md: 960,
  l: 1080,
  xl: 1150,
  xxl: 1920,
  infinity: Infinity,
  mobileMenu: 1050
};

export const font = {
  montseratt: '"Montserrat", sans-serif',
  roboto: '"Roboto", sans-serif',
  proxima: '"Proxima Nova", sans-serif'
};

export const media = {
  exactL: `@media screen and (min-width: ${
    breakpoints.l
  }px) and (max-width: ${breakpoints.xl - 1}px)`,
  exactM: `@media screen and (min-width: ${
    breakpoints.m
  }px) and (max-width: ${breakpoints.l - 1}px)`,
  exactS: `@media screen and (min-width: ${
    breakpoints.s
  }px) and (max-width: ${breakpoints.m - 1}px)`,
  exactXl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  exactXs: `@media screen and (min-width: ${
    breakpoints.xs
  }px) and (max-width: ${breakpoints.s - 1}px)`,
  exactxxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,
  l: `@media screen and (min-width: ${breakpoints.l}px)`,
  m: `@media screen and (min-width: ${breakpoints.m}px)`,
  maxL: `@media screen and (max-width: ${breakpoints.l - 1}px)`,
  maxM: `@media screen and (max-width: ${breakpoints.m - 1}px)`,
  maxMD: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  maxS: `@media screen and (max-width: ${breakpoints.s - 1}px)`,
  maxXl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  maxXs: `@media screen and (max-width: ${breakpoints.xs - 1}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  retina: "@media (-webkit-min-device-pixel-ratio: 2)",
  s: `@media screen and (min-width: ${breakpoints.s}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  xxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,
  zero: `@media screen and (min-width: ${breakpoints.zero}px)`,
  mobileMenuVisible: `@media screen and (max-width: ${breakpoints.mobileMenu}px)`,
  mobileMenuHidden: `@media screen and (min-width: ${breakpoints.mobileMenu +
    1}px)`
};

export const fontSizeBase = 16;
export const defaultTransitionTime = 0.3;

export const container = {
  large: "1366px",
  basic: "1060px",
  small: "890px",
  background: "(100vw - 1000px) / 2"
};

// helper functions
export function em(val: number, base = fontSizeBase) {
  return `${Math.round((val / base) * 10000) / 10000}em`;
}

export function rem(val: number) {
  return `${Math.round((val / fontSizeBase) * 10000) / 10000}rem`;
}

export interface Container {
  basic: string;
  large: string;
  small: string;
  background: string;
}

export interface Font {
  montserrat: string;
  proxima: string;
  roboto: string;
}

export interface Colors {
  black: string;
  white: string;
  gray: string;
  red: string;
  redHover: string;
  blue: string;
  blueDark: string;
  grayLight: string;
  grayDark: string;
  blueLight: string;
  grayBlue: string;
  blueGradient: string;
  secondary: string;
}

export interface Media {
  xxl: string;
  xl: string;
  l: string;
  m: string;
  md: string;
  s: string;
  xs: string;
  exactxxl: string;
  exactXl: string;
  exactL: string;
  exactM: string;
  exactS: string;
  exactXs: string;
  maxXl: string;
  maxL: string;
  maxM: string;
  maxMD: string;
  maxS: string;
  maxXs: string;
  retina: string;
  zero: string;
  mobileMenuVisible: string;
  mobileMenuHidden: string;
}

export interface Theme {
  colors: Colors;
  media: Media;
  em: (val: number, base: number) => string;
  rem: (val: number) => string;
  container: Container;
  font: Font;
}

export const theme: Theme = {
  colors,
  em,
  media,
  rem,
  container,
  font
};
